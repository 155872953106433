export var utils = {
	ajax : {
		normalize_error : err => {
			var response = err.response;
			var now = new Date();
			var normalize = {
				date : now,
				timestamp : now.getTime(),
				err : "inconnu",
				code : "inconnu",
				detail : []				
			}

			if(response == undefined) {
				normalize["err"] = "Erreur de réseaux lors de la requete";
				normalize["code"] = "network_error";
				normalize["detail"] = [err.stack];
				return normalize;
			}

			if(!("data" in response)) {
				normalize["err"] = "Erreur lors de la requete";
				normalize["code"] = "query_error_not_normalized";
				normalize["detail"] = [err.stack];
				return normalize;
			}

			if(typeof response.data != 'object') {
				normalize["err"] = "Erreur lors de la requete";
				normalize["code"] = "query_error_not_normalized";
				normalize["detail"] = [err.stack];
				return normalize;
			}

			normalize["err"] = ("err" in response.data) ? response.data.err : "Erreur inconnu";
			normalize["code"] = ("code" in response.data) ? response.data.code : "code_inconnu";
			normalize["detail"] = ("detail" in response.data) ? response.data.detail : [];
			return normalize;
		},
		makeHeader : () => {
			var headers = {}

			if(localStorage.getItem("adminerToken") != null) {
				headers['x-token-adminer'] = localStorage.getItem("adminerToken") 
			}

			if(localStorage.getItem("intranetToken") != null) {
				headers['x-token-intranet'] = localStorage.getItem("intranetToken") 
			}

			return headers
		}
	},
	display: {
		getMarque: function(){
			let rootp = window.location.origin
			if(rootp.includes("2fci")) return "ffci";
			if(rootp.includes("swiss")) return "l4s";
			if(rootp.includes("pluseo")) return "pluseo";
			return "novaseo"
		},
		getLogo: function(marque){
			if(marque == "ffci") return "https://2fci.com/storage/2020/02/logo-couleur-blanc.png";
			if(marque == "l4s") return "https://lead4swiss.ch/storage/2019/05/Logo_Final_WP.png";
			if(marque == "pluseo") return "https://www.pluseo.fr/storage/2020/02/logo-pluseo_03.png";
			return "https://nova-seo.com/storage/2021/01/cropped-Logo-Novaseo-HD.png";
		},
		getLogoLogin: function(marque){
			if(marque == "ffci") return "https://2fci.com/storage/2022/05/cropped-logo-couleur-e1653199932247.png";
			return utils.display.getLogo(marque);
		},
		getCurrentLogo: function(){
			let marque = utils.display.getMarque();
			return utils.display.getLogo(marque);
		},
		getCurrentLogoLogin: function(){
			let marque = utils.display.getMarque();
			return utils.display.getLogoLogin(marque);
		},
		isNova: function() {
			return utils.display.getMarque() == "novaseo"
		},
		marketikByMarque: function() {
			let marque = utils.display.getMarque();
			if(marque == "ffci") return "https://newmarketik.2fci.fr/";
			return "https://marketik.fr/";
		}
	}
}