export const mutations = {
	setToken: (state, vals) => {
		state.token = vals;
	},
	setAdminerToken: (state, vals) => {
		state.adminerToken = vals;
	},
	setAdminerIsLog: (state, vals) => {
		state.adminerIsLog = vals;
	},
	setIntranetToken: (state, vals) => {
		state.intranetToken = vals;
	},
	setIntranetTokenDecoded: (state, vals) => {
		state.intranetTokenDecoded = vals;
	},
	setIntranetIsLog: (state, vals) => {
		state.intranetIsLog = vals;
	},
	resetAll: (state) => {
        localStorage.removeItem("intranetToken");
        localStorage.removeItem("adminerToken");
		state.adminerToken = "";
		state.adminerIsLog = false;
		state.intranetToken = "";
		state.intranetIsLog = false;
	}
}
