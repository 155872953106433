import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

import global from './modules/global/global';
import auth from './modules/auth/auth';
import formulaires from './modules/formulaires/formulaires';


const store = new Vuex.Store({
  modules: {
    global,
    auth,
    formulaires
  },
  state: {

  },
  getters: {   

  },
  mutations: {

  },
})

export default store
