export const mutations = {
	setErrors: (state, vals) => {
		state.errors = vals;			
	},
	setNotif: (state, vals) => {
		state.notif = vals;			
	},
	setNotifMsg: (state, vals) => {
		state.notif_msg = vals;			
	},
	setNotifType: (state, vals) => {
		state.notif_type = vals;			
	},
	sendSuccessNotif: (state, vals) => {
		state.notif_type = "success";
		state.notif = true;
		state.notif_msg = vals;
	},
	sendInfoNotif: (state, vals) => {
		state.notif_type = "info";
		state.notif = true;
		state.notif_msg = vals;
	},
	sendWarningNotif: (state, vals) => {
		state.notif_type = "warning";
		state.notif = true;
		state.notif_msg = vals;
	}
}