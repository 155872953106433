import getters from './getters';
import * as actions from './actions';
import {mutations} from './mutations';


const state = {
    errors : [],
    notif: false,
    notif_msg : "",
    notif_type : "info"
}

export default {
    namespace: 'global/',
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}