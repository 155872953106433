import Vue from 'vue'
import Router from 'vue-router'
import jwt_decode from "jwt-decode";
import store from './store/store';
Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		{
			path: '/rapport',
			name: 'rapport',
			component: () => import('./components/formulaires/rapport.vue'),
			beforeEnter : (to, from, next) => {
				intranet_check(to, from, next);
			}
		},
		{
			path: '/form-cdc-papier',
			name: 'form-cdc-papier',
			component: () => import('./components/formulaires/cdcpapier.vue'),
			beforeEnter : (to, from, next) => {
				intranet_check(to, from, next);
			}
		},
		{
			path: '/form-fiche-param',
			name: 'form-fiche-param',
			component: () => import('./components/formulaires/ficheparam.vue'),
			beforeEnter : (to, from, next) => {
				intranet_check(to, from, next);
			}
		},
		{
			path: '/form-envoie-masse',
			name: 'form-envoie-masse',
			component: () => import('./components/formulaires/envoimasse.vue'),
			beforeEnter : (to, from, next) => {
				intranet_check(to, from, next);
			}
		},
		{
			path: '/form-ticket',
			name: 'form-ticket',
			component: () => import('./components/formulaires/ticket.vue'),
			beforeEnter : (to, from, next) => {
				intranet_check(to, from, next);
			}
		},
		{
			path: '/form-juridique',
			name: 'form-juridique',
			component: () => import('./components/formulaires/juridique.vue'),
			beforeEnter : (to, from, next) => {
				intranet_check(to, from, next);
			}
		},
		{
			path: '/form-bug',
			name: 'form-bug',
			component: () => import('./components/formulaires/bug.vue'),
			beforeEnter : (to, from, next) => {
				intranet_check(to, from, next);
			}
		},
		{
			path: '/form-comptabilite',
			name: 'form-comptabilite',
			component: () => import('./components/formulaires/comptabilite.vue'),
			beforeEnter : (to, from, next) => {
				intranet_check(to, from, next);
			}
		},
		{
			path: '/jira-suivi',
			name: 'jira-suivi',
			component: () => import('./components/formulaires/jira.vue'),
			beforeEnter : (to, from, next) => {
				intranet_check(to, from, next);
			}
		},
		{
			path: '/jira-suivi/:ref',
			name: 'jira-suivi-detail',
			component: () => import('./components/formulaires/jira_detail.vue'),
			beforeEnter : (to, from, next) => {
				intranet_check(to, from, next);
			}
		},
		{
			path: '/seminaire',
			name: 'seminaire',
			component: () => import('./components/page/seminaireView.vue'),
			beforeEnter : (to, from, next) => {
				intranet_check(to, from, next);
			}
		},
		{
			path: '/formation',
			name: 'formation',
			component: () => import('./components/page/formationView.vue'),
			beforeEnter : (to, from, next) => {
				intranet_check(to, from, next);
			}
		},
		{
			path: '/',
			name: 'home',
			component: () => import('./views/Dashboard.vue'),
			beforeEnter : (to, from, next) => {
				auto_connexion(to, from, next);
			}
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('./views/Login.vue'),
		},
		{
			path: '/redirect-google-auth',
			name: 'redirect',
			component: () => import('./views/Redirec.vue'),
		},
	],
})

/*function admin_check(to, from, next) {
	var adminerToken = localStorage.getItem("adminerToken");
	var checkAdminer = false;

	if (adminerToken == null) {
		next('/login')
	} else {
		try {
			var decodeTokenAdminer = jwt_decode(localStorage.getItem('adminerToken'));
			checkAdminer = decodeTokenAdminer != null ? (decodeTokenAdminer.exp * 1000) > new Date().getTime() : false;
		} catch(e) {
			checkAdminer = false;
		}

		if(checkAdminer == false) {
			localStorage.removeItem('adminerToken');
			localStorage.removeItem('intranetToken');
			next('/login');
		} else {
			next();
		}
	}
}*/


function intranet_check(to, from, next) {
	var intranetToken = localStorage.getItem("intranetToken");
	var checkTIntra = false;

	if (intranetToken == null) {
		next('/login')
	} else {
		try	{
			var decodeTokenIntra = jwt_decode(localStorage.getItem('intranetToken'));		
			checkTIntra = decodeTokenIntra != null ? (decodeTokenIntra.exp * 1000) > new Date().getTime() : false;
			store.state.auth.intranetToken = localStorage.getItem('intranetToken');
			store.state.auth.intranetTokenDecoded = decodeTokenIntra;
			store.state.auth.intranetIsLog = true;
		} catch(e) {
			checkTIntra = false;
		}

		if(checkTIntra == false) {
			localStorage.removeItem('adminerToken');
			localStorage.removeItem('intranetToken');
			store.state.auth.intranetToken = "";
			store.state.auth.intranetIsLog = false;
			next('/login');
		} else {
			next();
		}
	}
}


function auto_connexion(to, from, next) {
	var adminerToken = localStorage.getItem("adminerToken");
	var intranetToken = localStorage.getItem("intranetToken");
	var checkAdminer = false;
	var checkTIntra = false;

	if (adminerToken == null && intranetToken == null) {
		next('/login')
	} else {
		try {
			var decodeTokenAdminer = jwt_decode(localStorage.getItem('adminerToken'));
			checkAdminer = decodeTokenAdminer != null ? (decodeTokenAdminer.exp * 1000) > new Date().getTime() : false;
			store.state.auth.adminerToken = localStorage.getItem('adminerToken');
			store.state.auth.adminerIsLog = true;
		} catch(e) {
			checkAdminer = false;
		}

		try	{
			var decodeTokenIntra = jwt_decode(localStorage.getItem('intranetToken'));		
			checkTIntra = decodeTokenIntra != null ? (decodeTokenIntra.exp * 1000) > new Date().getTime() : false;
			store.state.auth.intranetToken = localStorage.getItem('intranetToken');
			store.state.auth.intranetTokenDecoded = decodeTokenIntra;
			store.state.auth.intranetIsLog = true;
		} catch(e) {
			checkTIntra = false;
		}

		if(checkAdminer == false && checkTIntra == false) {
			localStorage.removeItem('adminerToken');
			localStorage.removeItem('intranetToken');
			store.state.auth.adminerToken = "";
			store.state.auth.adminerIsLog = false;
			store.state.auth.intranetToken = "";
			store.state.auth.intranetIsLog = false;
			store.state.auth.intranetTokenDecoded = {};
			next('/login');
		} else {
			next();
		}
	}
}