import getters from './getters';
import * as actions from './actions';
import {mutations} from './mutations';


const state = {
  token: "",
  adminerToken: "",
  adminerIsLog: false,
  intranetToken: "",
  intranetTokenDecoded: {},
  intranetIsLog: false
}

export default {
  namespace: 'store/',
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
