import axios from 'axios'
import {api} from '../../../api.js';
import {utils} from '../../../utils.js';


/* =================================================================== */
/* ======================== récupération tokens ====================== */
/* =================================================================== */
export const getTokenAction = ({commit}, vals) => {
    var headers = utils.ajax.makeHeader();
    headers['Content-Type'] = 'text/plain';
    commit("resetAll");

    var dataJson = JSON.stringify({
        code : vals,
    });

    return axios.post(api.auth.getToken(), dataJson, {
        headers: headers
    })
    .then(response => {
        if(response.data.intranet.valide) {
            localStorage.setItem("intranetToken", response.data.intranet.token);
            commit("setIntranetToken", response.data.intranet.token);
            commit("setIntranetIsLog", true);
        }
        
        if(response.data.adminer.valide) {
            localStorage.setItem("adminerToken", response.data.adminer.token);
            commit("setAdminerToken", response.data.adminer.token);
            commit("setAdminerIsLog", true);
        }
    })
    .catch(error => {
        commit("resetAll");
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    })
}

/* =================================================================== */
/* ======================== refresh tokens ====================== */
/* =================================================================== */
export const refreshTokenAction = ({commit}) => {
    var url = api.auth.regenToken();
    var headers = utils.ajax.makeHeader();
    
    return axios.get(url, {
        headers
    })
    .then(response => {
        if(response.data.intranet.valide) {
            localStorage.setItem("intranetToken", response.data.intranet.token);
        }
        
        if(response.data.adminer.valide) {
            localStorage.setItem("adminerToken", response.data.adminer.token); 
        }
    })
    .catch(error => {
        commit("resetAll");
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    })
}