import axios from 'axios'
import slugify from 'slugify'
import {api} from '../../../api.js';
import {utils} from '../../../utils.js';


//-----------------------------
// Search cab
//-----------------------------
export const searchCabAction = ({commit}, vals) => {
    var url = api.formulaires.cabSearch(vals);
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.get(url, {headers})
    .then(response => {
        return response
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    })
}



//-----------------------------
// Auth
//-----------------------------
export const getTokenAction = ({commit}, vals) => {
    var dataJson = JSON.stringify({
        code : vals,
    });

    var url = api.auth.getToken();
    return axios.post(url, dataJson, {
        headers: { 'Content-Type': 'text/plain' }
    })
    .then(response => {
        commit('setToken', response.data)
        if(response.data.intranet.token != '') {
            localStorage.setItem("intranetToken", response.data.intranet.token);
        }
        
        if(response.data.adminer.token != '') {
            localStorage.setItem("adminerToken", response.data.adminer.token); 
        }
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    })
}


//-----------------------------
// Send Files
//-----------------------------
export const sendFile = ({commit}, vals) => {
    var url = api.formulaires.sendFile(slugify(vals.name));
    var headers = utils.ajax.makeHeader();
    headers['Content-Type'] = 'multipart/form-data';
    commit("global/setErrors", [], { root: true });

    return axios.post(url, vals.formData, {
        headers: headers,
        cancelToken: vals.cancel.token
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        if(axios.isCancel(error)) {
            return error;
        }

        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        return e;
    });
}



//-----------------------------
// Form Juridique
//-----------------------------
export const sendJuridique = ({commit}, vals) => {
    var url = api.formulaires.postJuridique();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.post(url, vals, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}



//-----------------------------
// Form bug
//-----------------------------
export const getBugListType = ({commit}) => {
    var url = api.formulaires.getBugListType();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.get(url, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}


export const sendBug = ({commit}, vals) => {
    var url = api.formulaires.postBug();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.post(url, vals, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}


//-----------------------------
// Form admin
//-----------------------------
export const getAdminListMotif = ({commit}) => {
    var url = api.formulaires.getAdminListMotif();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.get(url, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}


export const sendAdminInterne = ({commit}, vals) => {
    var url = api.formulaires.postAdminInterne();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.post(url, vals, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}


export const sendAdminClient = ({commit}, vals) => {
    var url = api.formulaires.postAdminClient();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.post(url, vals, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}


//-----------------------------
// Form Ticket
//-----------------------------
export const getTicketListMotif = ({commit}) => {
    var url = api.formulaires.getTicketListMotif();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.get(url, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}



export const sendTicket = ({commit}, vals) => {
    var url = api.formulaires.postTicket();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.post(url, vals, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}



//-----------------------------
// Form envoie masse
//-----------------------------
export const getEnvoieMasseListMotif = ({commit}) => {
    var url = api.formulaires.getEnvoieMasseListMotif();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.get(url, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}


export const sendEnvoieMasse = ({commit}, vals) => {
    var url = api.formulaires.postEnvoieMasse();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.post(url, vals, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}



export const sendCdcPapier = ({commit}, vals) => {
    var url = api.formulaires.postSendCdcPapier();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.post(url, vals, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}



export const sendRapportSuivi = ({commit}, vals) => {
    var url = api.formulaires.postSendRapportSuivi();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.post(url, vals, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}


//-----------------------------
// Jira
//-----------------------------
export const searchJiraDemandes = ({commit}, vals) => {
    var url = api.formulaires.searchJiraDemandes();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.post(url, vals, {
        headers: headers
    })
    .then(response => {
        return response.data
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    })
}


export const getJiraDemandes = ({commit}, ref) => {
    var url = api.formulaires.searchJiraDemande(ref);
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.get(url, {
        headers: headers
    })
    .then(response => {
        return response.data
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    })
}


export const getJiraSla = ({commit}, ref) => {
    var url = api.formulaires.searchJiraDemandeSLA(ref);
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.get(url, {
        headers: headers
    })
    .then(response => {
        return response.data
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    })
}


export const sendCommentaire = ({commit}, vals) => {
    var url = api.formulaires.postSendCommentaire();
    var headers = utils.ajax.makeHeader()
    commit("global/setErrors", [], { root: true });
    
    return axios.post(url, vals, {
        headers: headers
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        var e = utils.ajax.normalize_error(error);
        commit("global/setErrors", [e], { root: true });
        throw e;
    });
}