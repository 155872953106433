<template>
    <div>
        <v-alert
            dense
            outlined
            type="error"
            style="margin-bottom: 80px;"
            v-if="getErrors().length > 0"
        >
			<div v-for="(x, i) in getErrors()" v-bind:key="i">
				Erreur : {{x.err}}<br>
				Code : {{x.code}}<br>
				Detail :
					<span v-on:click="more=true" v-if="!more" class="detail_action">voir</span>
					<span v-if="more"> {{x.detail}}</span>
					<span v-on:click="more=false" v-if="more" class="detail_action"> masquer</span>
			</div>
        </v-alert>
    </div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'ErrorAlerte',
		data() {
			return {
				name: '',
				more: false
			}
		},
		methods: {
			...mapGetters("global", [
				"getErrors",
			]),
		}
	};
</script>

<style lang="scss" scoped>
.detail_action
{
	cursor: pointer;
	font-weight: bold;
}
</style>