import getters from './getters';
import * as actions from './actions';
import {mutations} from './mutations';


const state = {

}

export default {
  namespace: 'store/',
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
