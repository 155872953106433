<template>
    <v-app id="inspire" :class="getMarque()">
        <v-navigation-drawer v-model="drawer" app class="menu">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        <img :src="getLogo()">
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <template v-slot:append>
                <div class="profil">
                    <img src="./assets/tools_icons/user.svg">
                    <div>
                        Bonjour, <br>
                        <span>{{ getIntranetName() }}</span>
                    </div>
                </div>
            </template>
        </v-navigation-drawer>
        <v-app-bar app flat color="white">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2 logout"
              @click="toFAQ"
              v-if="getIsLog() && isNova()"
              depressed
            >
              F.A.Q. Nova-Seo
            </v-btn>
            <v-btn
              class="ma-2 logout"
              @click="toBook"
              v-if="getIsLog() && isNova()"
              depressed
            >
              Book Nova-Seo
            </v-btn>
            <v-btn
              class="ma-2 logout"
              to="/formation"
              v-if="getIsLog() && isNova()"
              depressed
            >
              Formation commerciale
            </v-btn>
            <v-btn
              class="ma-2 logout"
              to="/seminaire"
              v-if="getIsLog() && isNova() && getIntranetFonction() != '2fci'"
              depressed
            >
              Vidéos du séminaire
            </v-btn>
            <v-btn
              class="ma-2 logout"
              text
              @click="logout"
              v-if="getIsLog()"
            >
              <v-icon left>mdi-logout</v-icon>
              Déconnexion
            </v-btn>
        </v-app-bar>
        <v-main>
            <ErrorAlerte></ErrorAlerte>
            <router-view class="pa-10"></router-view>
        </v-main>
        <v-snackbar
            v-model="notif"
            :timeout="4000"
            :color="getNotifType()"
            border="left"
            dark
        >
        {{ getNotifMsg() }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="notif = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ErrorAlerte from './components/static/error_alerte.vue';
import jwt_decode from "jwt-decode";
import { utils } from "./utils";

export default {
    name: 'App',
    components : {
        ErrorAlerte
    },
    data: () => ({
        drawer: null,
        tickerToken : null
    }),
    computed: {
        notif : {
            get() {
                return this.getNotif();
            },
            set(val) {
                this.setNotif(val);
            }
        }
    },
    methods: {
        ...mapActions('auth', [
            "refreshTokenAction",
        ]),
        ...mapGetters("auth", [
            "getIsLog",
            "getIntranetName",
            "getIntranetFonction"
        ]),
        ...mapMutations("auth", [
            "resetAll"
        ]),
        ...mapMutations("global", [
            "setNotif"
        ]),
        ...mapGetters("global", [
            "getErrors",
            "getNotif",
            "getNotifMsg",
            "getNotifType",
            "getIsLogged"
        ]),
        toBook: function() {
            window.location.href = "https://book.nova-seo.com/";
        },
        toFAQ: function() {
            window.location.href = "https://faq.nova-seo.com/";
        },
        logout: function() {
            this.resetAll();
            this.$router.push('/login');
        },
        getLogo: function() {
            return utils.display.getCurrentLogo();
        },
        getMarque: function() {
            return [utils.display.getMarque()];
        },
        isNova: function() {
            return utils.display.isNova()
        }
    },
    created: function() {
        var _this = this;
        this.tickerToken = setInterval(function() {
            if(_this.getIsLog() == false) {
                localStorage.removeItem("tickActualisation");
                return false;
            }

            if(localStorage.getItem("tickActualisation") == null) {
                localStorage.setItem("tickActualisation", 0);
            }

            var tickStorage = parseInt(localStorage.getItem("tickActualisation")) + 15
            localStorage.setItem("tickActualisation", tickStorage);

            try {
                //var tickStorage = localStorage.getItem("tickActualisation");
                var decodeTokenIntra = jwt_decode(localStorage.getItem('intranetToken'));
                var decodeTokenAdminer = jwt_decode(localStorage.getItem('adminerToken'));
                var tokenIntraExp = decodeTokenIntra.exp;
                var tokenAdminExp = decodeTokenAdminer.exp;
                var tokenAdminExpLimit = tokenIntraExp - (60*30);
                var tokenIntraExpLimit = tokenAdminExp - (60*30);
                var currentTimeStamp = new Date().getTime() / 1000;      
                
                if(tokenIntraExpLimit < currentTimeStamp || tokenAdminExpLimit < currentTimeStamp) 
                {
                    _this.refreshTokenAction();
                    localStorage.setItem("tickActualisation", 0);
                }
                else if(tickStorage > 600)
                {
                    localStorage.setItem("tickActualisation", 0);
                    _this.refreshTokenAction();
                    console.log("actualisation du token", new Date())
                }
            } catch(e) {
                console.log(e);
            }
        }, 15000)
    },
    beforeDestroy: function() {
        clearInterval(this.tickerToken);
    }
};
</script>

<style lang="scss">
#inspire
{
    .menu
    {
        .title {
            text-align: center;
            margin-bottom: 45px;
            img {
                width: 90%;
            }
        }

        .profil {
            position: absolute;
            bottom: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 60px;
                margin: 10px 15px 0 0;
            }
            span {
                font-weight: bold;
            }
        }
    }
}

#inspire.ffci
{
    .menu
    {
        background-color: #132332;

        .profil {
            color: white;
        }
    }
}

#inspire.pluseo
{
    .menu
    {
        .title
        {
            img
            {
                width: auto;
                height: 60px;
            }
        }
    }
}
</style>