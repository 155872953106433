export default {
    getAdminerToken 		: state => state.adminerToken,
    getAdminerIsLog			: state => state.adminerIsLog,
    getIntranetToken		: state => state.intranetToken,
    getIntranetTokenDecoded	: state => state.intranetTokenDecoded,
    getIntranetIsLog		: state => state.intranetIsLog,
	getIntranetFonction 	: state => {
		if(state.intranetTokenDecoded.data == undefined) {
			return "";
		}

		if(state.intranetTokenDecoded.data.email.includes("pluseo")) {
			return "pluseo";
		}

		if(state.intranetTokenDecoded.data.email.includes("2fci")) {
			return "2fci";
		}

		if(state.intranetTokenDecoded.data.fonction == undefined) {
			return "";
		}

		if(state.intranetTokenDecoded.data.email != undefined) {
			var email = state.intranetTokenDecoded.data.email
			var emails_da = ["pignards@cometik.com","pignards@altanova-seo.com","pignards@nova-seo.com","poyera@cometik.com","poyera@altanova-seo.com","poyera@nova-seo.com","lemarchandv@cometik.com","lemarchandv@altanova-seo.com","lemarchandv@nova-seo.com","guerina@cometik.com","guerina@altanova-seo.com","guerina@nova-seo.com","guilbertv@cometik.com","cognetf@cometik.com","celine@cometik.com","rh@cometik.com","tours@cometik.com","guilbertv@altanova-seo.com","guilbertv@nova-seo.com", "arraizc@nova-seo.com", "arraizc@cometik.com", "hamonv@nova-seo.com", "hamonv@cometik.com"];
			var emails_dz = ["pignards@cometik.com.test-google-a.com","pignards@altanova-seo.com","pignards@nova-seo.com","pignards@cometik.com","lemarchandv@cometik.com.test-google-a.com","lemarchandv@altanova-seo.com","lemarchandv@nova-seo.com","lemarchandv@cometik.com","guerina@cometik.com.test-google-a.com","guerina@altanova-seo.com","guerina@nova-seo.com","guerina@cometik.com","poyera@cometik.com.test-google-a.com","poyera@altanova-seo.com","poyera@nova-seo.com","poyera@cometik.com"];

			if(emails_dz.includes(email)) {
				return "dz"
			}

			if(emails_da.includes(email)) {
				return "da"
			}
		}

		var adminAlias = ["pdg", "std", "daf", "cpt", "adm", "Res", "0", 0];
		if(adminAlias.includes(state.intranetTokenDecoded.data.fonction)) {
			return "admin";
		}

		var suiviAlias = ["rsc", "csl"];
		if(suiviAlias.includes(state.intranetTokenDecoded.data.fonction)) {
			return "suivi";
		}

		return state.intranetTokenDecoded.data.fonction; 	
	},
	getIntranetName 	: state => {
		if(state.intranetTokenDecoded.data == undefined) {
			return "";
		}

		if(state.intranetTokenDecoded.data.nom == undefined || state.intranetTokenDecoded.data.prenom == undefined) {
			return "";
		}

		return state.intranetTokenDecoded.data.nom + " " + state.intranetTokenDecoded.data.prenom; 	
	},
	getIsLog 				: state => {
		return state.adminerIsLog || state.intranetIsLog
	}
}